<template>
  <div class="form-login">
    <BrandCustomCard subtitle="Acceso usuarios">
      <v-form novalidate @submit.prevent="onSubmit">
        <v-text-field
          v-model.trim="$v.formFields.email.$model"
          data-cy="email-input"
          type="email"
          label="Email"
          prepend-icon="mdi-at"
          :error-messages="checkFieldErrors('email')"
          @input="touchField('email')"
          @blur="touchField('email')"
        />
        <v-text-field
          v-model="$v.formFields.password.$model"
          data-cy="password-input"
          label="Contraseña"
          prepend-icon="mdi-lock-outline"
          :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="togglePassword ? 'text' : 'password'"
          :error-messages="checkFieldErrors('password')"
          @input="touchField('password')"
          @blur="touchField('password')"
          @click:append="togglePassword = !togglePassword"
        />
        <v-card-actions class="d-block">
          <!-- Form action buttons -->
          <FormButtons
            :accept-button-v-options="acceptButtonVOptions"
            align-buttons="center"
            accept-button-text="Acceder"
            :accept-button-loading="formProcessing"
            cancel-button-hide
          />
          <!-- Link to others views -->
          <div class="mt-6 bottom-links">
            <router-link to="/activation-form">
              Activación clientes
            </router-link>
            <router-link class="grey--text" to="/recover-password">
              Contraseña olvidada
            </router-link>
          </div>
        </v-card-actions>
      </v-form>
    </BrandCustomCard>
  </div>
</template>

<script>
// Components
import BrandCustomCard from '@/components/ui/BrandCustomCard'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'
// Vuex
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  components: { BrandCustomCard, FormButtons },
  mixins: [validationMixin, formMixin, uiMixin],
  data() {
    return {
      // Form
      formFields: {
        email: null,
        password: null
      },
      formFieldsValidations: {
        email: {
          required: 'Campo obligatorio',
          email: 'Formato incorrecto de email'
        },
        password: {
          required: 'Campo obligatorio',
          minLength: 'Debe contener al menos 6 caracteres'
        }
      },
      togglePassword: false // to show or hide password field
    }
  },
  computed: {
    /**
     * We need a computed option to pass an object with the differents
     * options about the vuetify buttons:
     *
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    acceptButtonVOptions() {
      return {
        block: true,
        color: 'primary',
        large: true
      }
    }
  },
  created() {
    this.closeSession()
  },
  methods: {
    ...mapActions('authentication', ['login']),
    /**
     * Is triggering after the form is correctly
     * validated by "Vue-validate"
     */
    async afterSubmit() {
      try {
        await this.login({ ...this.formFields })
      } catch (error) {
        // Show error
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/theme/variables.scss';
.form-login {
  height: 100%;
  .bottom-links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    a {
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}
@media (max-width: 768px) {
  .form-login {
    .bottom-links {
      a {
        &:first-child {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
</style>
